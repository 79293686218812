import styled from "styled-components";
import {
    PrimaryGreenColor,
    PurpleBackgroundColor,
    PurpleBtnColor, PurpleSoftTextColor,
    PurpleTextColor,
    TextBlack
} from "../../components/Data/color";
import {IoMdArrowRoundBack} from "react-icons/io";
import {FaCheckCircle} from "react-icons/fa";
import {FaCheckCircleIcon, IoMdArrowRoundBackIcon} from "../../components/IconCss/styled";

const WrapperLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  flex-direction: column;
  margin-bottom: 32px;
  //background-color: aqua;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;
const TitleLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  //background-color: #61dafb;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 96%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
  }
`
const TitleTextStyle = styled.div `
  display: flex;
  width : 900px;
  //background-color: #FCFCFC;
  
  justify-content: flex-start;
  font-size: 36px;
  font-weight: bolder;
  color: ${TextBlack};
  
  @media (max-width: 768px) {
   font-size: 24px;
    width: 90%;
    justify-content: space-between;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
  }
`
const ContentLayout = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: 24px;
  width: 900px;
  margin-top: 64px;
  @media (max-width: 768px) {
    //flex-direction: column;
    //justify-content: center;
    width: 100%;
    margin-top: 24px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 96%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
    //background-color: #61dafb;
  }
  //background-color: #61dafb;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 16px;
  margin-bottom: 24px;
  //padding: 24px;
  margin-top: 32px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    width: 92%;
    margin-top: 24px;
    height: 80%;
  }
`
const ContainerTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  
`
const ContainerTitleText = styled.div`
  margin-left: 8px;
  font-size: 24px;
  font-weight: bold;
  //background-color: #61dafb;
  line-height: 32px;
  @media (max-width: 768px) {
    //flex-direction: column;
    //justify-content: center;
    //width: 90%;
    font-size: 18px;
    line-height: 24px;
  }
`
const ContainerTitleColorText = styled.div`
  margin-left: 16px;
  color: ${PurpleSoftTextColor};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  
  @media (max-width: 768px) {
    //flex-direction: column;
    //justify-content: center;
    //width: 90%;
    line-height: 24px;
    margin-left: 12px;
    font-size: 18px;
  }
`
const ContainerDetailText = styled.div`
  //color: ${PurpleTextColor};
  margin-left: 72px;
  
  margin-top: 12px;
  font-size: 20px;
  color : ${TextBlack};
  font-weight: bold;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    //flex-direction: column;
    //justify-content: center;
    //width: 90%;
    margin-left: 44px;
    font-size: 16px;
  }
`;

const FilterLayout = styled.div`
  display: flex;
  //background-color: #61dafb;
  width: 900px;
  flex-direction: row;
  justify-content: space-between;
  //background-color: #61dafb;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    //background-color: #282c34;
    //justify-content: space-between;
    //font-size: 24px;
    flex-direction: column;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
  }
`
const FilterInnerLayout = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    
    //font-size: 24px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
  }
`
const IconLayout = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 16px;
    width: 40%;
    align-self: flex-end;
    margin-right: 16px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 40%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
   // background-color: red;
    justify-content: flex-end;
  }


`;
const FilterContainer = styled.div `
  border: 2px solid ${PrimaryGreenColor};
  padding :12px;
  
  border-radius: 12px;
  margin-right: 24px;
  color: ${PrimaryGreenColor};
  font-weight: bold;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 12px;
    //padding: 8px;
    //justify-content: space-evenly;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 0;
    
  }
`
const CurrentFilterText  = styled.div`
  display: flex;
  //padding-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid grey;
  @media (max-width: 768px) {
    width: 80%;
    font-size: 12px; 
    margin: 0;
    border: none;
    justify-content: center;
    //background-color: #61dafb;
    border-bottom: 2px solid grey;
  }
`
const CheckBtn = styled.div`
  border: 2px solid ${PrimaryGreenColor};
  border-radius: 8px;
  width: 100px;
  text-align: center;
  margin-bottom: 12px;
  margin-right: 12px;
  font-weight: bold;
  @media (max-width: 768px) {
    width: 80px;
    font-size: 14px;
  }
  
  color : ${PrimaryGreenColor}
  
`
const CheckBtnLayout= styled.div`
  display: flex;
  justify-content: end;
`




export const s = {
    WrapperLayout,
    TitleLayout,
    TitleTextStyle,
    ContentLayout,
    ContentContainer,
    ContainerTitleText,
    ContainerDetailText,
    ContainerTitleWrapper,
    ContainerTitleColorText,
    FilterLayout,
    FilterContainer,
    CurrentFilterText,
    FilterInnerLayout,
    CheckBtn,
    CheckBtnLayout,
    IconLayout,
    IoMdArrowRoundBackIcon,
    FaCheckCircleIcon

};
