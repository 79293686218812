import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LoginScreen from "./screeens/Login/LoginScreen";
import HomeScreen from "./screeens/Home/HomeScreen";
import SurveyRequestScreen from "./screeens/ResearchRequestCheck/SurveyRequestScreen";
import AddNoticeScreen from "./screeens/AddNoticeScreen/AddNoticeScreen";
import ResearchRegistPage1 from "./screeens/ResearchRegist/ResearchRegistPage1";

import PointChangeScreen from "./screeens/PointChangeScreen/PointChangeScreen";
import HelpAskScreen from "./screeens/HelpAskScreen/HelpAskScreen";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginScreen/>}/>
                <Route path="/home" element={<HomeScreen/>}/>
                <Route path="/home/research-request-check" element = {<SurveyRequestScreen/>}/>
                <Route path="/home/add-notice" element={<AddNoticeScreen/>}></Route>
                <Route path="/home/regist-research/p1" element={<ResearchRegistPage1/>} ></Route>
                <Route path="/home/point-change" element={<PointChangeScreen/>}/>
                <Route path="/home/ask" element={<HelpAskScreen/>}/>
            </Routes>
        </Router>
    );
}

export default App;
