import styled from "styled-components";
import Modal from 'react-modal'
import {PrimaryGreenColor} from "../../Data/color";

const ModalInnerContainer = styled.div `
  flex-direction: column;
  width : 400px;
  height: 270px;
  display: flex;
  
  //background-color: #61dafb;
  @media (max-width: 768px) {
    width: 100%;
    height: 80%;
  }

`
const TitleContainer = styled.div`
  font-size : 36px;
  font-weight: bolder;
  align-self: start;
  justify-self: start;
  margin-left: 12px;
  color: ${PrimaryGreenColor};
  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const ContentContainer = styled.div`
  font-size : 24px;
  font-weight: normal;
  margin-top: 60px;
  margin-left: 12px;
  margin-right: 12px ;
  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 20px;
  }
  
`;

const BtnContainer = styled.div
`
  //background-color: #61dafb;
  align-self: end;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`

const BtnStyle = styled.button`
  display: flex;
  width : 100px;
  margin-bottom: 12px;
  justify-content: center;
  align-items: center;
  //height: 50px;
  font-size: 20px;
  //padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
  margin-top: 80px;
  border-radius: 12px;
  border : none;
  background-color : #448241;
  color: white;
  &:active {
    background-color: #8ebf8c;
    box-shadow: inset -.3rem -.1rem 1.4rem  #5d965a, inset .3rem .4rem .8rem #448241;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
    font-size: 16px;
    margin-right: 4px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
  }
  //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;
export const s = {
    TitleContainer,
    ModalInnerContainer,
    ContentContainer,
    BtnStyle,
    BtnContainer
}
