import React from "react";
import {s} from ".//styled";
import MenuContainer from '../../components/HomeMenuContainer'
import AcceptCheckIconSrc from "../../assets/Img/Icons/AcceptCheck.svg";
import NoticeRegisterIconSrc from "../../assets/Img/Icons/NoticeRegister.svg";
import PointChangeIconSrc from "../../assets/Img/Icons/PointChangeRequestCheckIcon.svg";
import ResearchRegisterIconSrc from "../../assets/Img/Icons/ResearchRegister.svg";
import ResearchRequestIconSrc from "../../assets/Img/Icons/ResearchRequestCheckIcon.svg";
import HelpAskIconSrc from "../../assets/Img/Icons/HelpAsk.svg"
import {CiLogout} from "react-icons/ci";
import {RiLogoutCircleLine} from "react-icons/ri";
import {PrimaryGreenColor} from "../../components/Data/color";

// 로그인 하면 가장 먼저 나오는 메뉴 선택 화면.
// 메뉴 컨테이너 5개가 반복되는 컴포넌트라서 <MenuContainer> 를 함수를 별도로 만들었음.
// 그 함수에 이동할 URL, 컨테이너 텍스트, 컨테이너 이미지 아이콘을 매개변수로 넘겨줌.
function HomeScreen() {
    return (
        <s.WrapperLayout>
            <s.RowLayout>
                <MenuContainer targetRouter="/home/research-request-check" menuTitle={"리서치 요청 확인"}
                               icon={ResearchRequestIconSrc}></MenuContainer>
                <MenuContainer targetRouter="/home/regist-research/p1" menuTitle={"리서치 등록"}
                               icon={ResearchRegisterIconSrc}></MenuContainer>
                <MenuContainer targetRouter="/home/add-notice" menuTitle={"공지사항 등록"}
                               icon={NoticeRegisterIconSrc}></MenuContainer>
            </s.RowLayout>
            <s.RowLayout>
                <MenuContainer targetRouter="/home/point-change" menuTitle={"포인트 교환 요청 확인"}
                               icon={PointChangeIconSrc}></MenuContainer>
                <MenuContainer targetRouter="/home/ask" menuTitle={"문의하기"}
                               icon={HelpAskIconSrc}></MenuContainer>
            </s.RowLayout>

            <s.LogoutContainer>

                    <RiLogoutCircleLine size={48} color={PrimaryGreenColor}/>
                    <s.LogoutText>로그아웃</s.LogoutText>

            </s.LogoutContainer>


        </s.WrapperLayout>
    )
}

export default HomeScreen

