import styled from "styled-components";
import {IoMdArrowRoundBack} from "react-icons/io";
import {PrimaryGreenColor} from "../Data/color";
import {FaCheckCircle} from "react-icons/fa";
import {FaHeadphones} from "react-icons/fa6";

export const IoMdArrowRoundBackIcon = styled(IoMdArrowRoundBack)`
  color: ${PrimaryGreenColor};
  font-size: 48px;

  // 태블릿 화면에서 적용될 스타일
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    
    //background-color: #61dafb;
    padding: 0;
    margin: 0;
  }
`;

export const FaCheckCircleIcon = styled(FaCheckCircle)`
  color: ${PrimaryGreenColor};
  font-size: 36px;
  margin-left: 16px;
  // 태블릿 화면에서 적용될 스타일
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    margin-left: 16px;
    //background-color: #61dafb;
    
  }
`;
export const FaHeadphonesIcon = styled(FaHeadphones)`
  color: ${PrimaryGreenColor};
  font-size: 36px;
  margin-left: 24px;
  margin-right: 4px;
  // 태블릿 화면에서 적용될 스타일
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    margin-left: 16px;
    //background-color: #61dafb;
    
  }
`;

