// DropdownMenu.tsx

import React, {useState, useEffect} from 'react';
import {s} from './styled'

interface DropdownMenuProps {
    options: string[];
    onSelect: (selectedOption: string) => void;
}

const SidoDropdownMenu: React.FC<DropdownMenuProps> = ({options, onSelect}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    useEffect(() => {
        if (selectedOption !== null) {
            onSelect(selectedOption);
        }
    }, [selectedOption, onSelect]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <s.DropDownWrapper>
            <s.JustifyBetween>
                <s.InputFormStyle onClick={handleToggle}>
                    {selectedOption || '선택해주세요. '}
                </s.InputFormStyle>
                <s.ArrowStyle>▼</s.ArrowStyle>
            </s.JustifyBetween>
            {isOpen && (
                <s.UlStyle className="dropdown-menu">
                    {options.map((option) => (
                        <s.LIStyle key={option} onClick={() => handleOptionClick(option)}>
                            {option}
                        </s.LIStyle>
                    ))}
                </s.UlStyle>
            )}
        </s.DropDownWrapper>
    );
};

export default SidoDropdownMenu;
