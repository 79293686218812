export const TextBlack = "#0F1035"
export const PurpleBackgroundColor = "#F6EEFF"
export const PurpleTextColor = "#7824CB";

export const PurpleSoftTextColor = '#C68CFF'
export const PurpleBtnColor = "#C9A7EB";



export const OrangeBackGroundColor = "#FFEADF";

export const OrangeTextColor = "#FF5C00";


export const SkyBlueBackgroundColor = "#D9F2FF";

export const SkyBlueTextColor = "#067BBD";

export const PrimaryGreenColor = "#448241";
//export const testcolor = "white"

