import React, {useEffect, useState} from 'react'
import {s} from './styled'
import {useNavigate} from "react-router-dom";
import {PrimaryGreenColor, PurpleBtnColor} from "../../components/Data/color";
import {MdCancel} from "react-icons/md";
import {FaSearch} from "react-icons/fa";
import axios from "axios";
import {BaseUrl} from "../../components/Data/baseUrl";
import LoadingComponent from "../../components/Modal/LoadingModal/LoadingModal";
import SuccessModal from "../../components/Modal/SmallModal/SuccessModal";
import {PointExchangeData, PointExchangeRequest} from "../../components/types/DtoTypes/PointChangeDto";
import {ResponseData} from "../../components/types/DtoTypes/AddNoticeDto";

function PointChangeScreen() {
    const accessToken = localStorage.getItem("accessToken");
    console.log(accessToken)
    const [btnClickState, setBtnClickState] = useState(false);
    const [pointExchangeData, setPointExchangeData]
        = useState<PointExchangeRequest | null>(null);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리
    const [modalText, setModalText] = useState("로딩중");
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const backBtnClick = () => {
        navigate(-1)
    }

    const SendMoneyBtnClick = async (id: number) => {
        try {
            setModalText("로딩중")
            setIsLoading(true)

            // 서버에서 해당 거래를 송금 완료로 변경하는 API 호출
            const response = await axios.patch<ResponseData>(
                BaseUrl + `/admin/point/changed?idPointHistory=${id}`,
                {

                },
                {
                    headers : {
                        authorization : accessToken
                    }
                }


            );
            //localStorage.setItem("accessToken", response.headers["authorization"])
            // 클라이언트에서 로컬 상태 업데이트: 해당 거래를 화면에서 제거
            setPointExchangeData((prevData: PointExchangeRequest | null) => ({
                ...prevData!,
                data: prevData?.data.filter((item) => item.id !== id) || [],
            }));
            setModalText(response.data.message)
            openModal()
        } catch (e) {
            setModalText("에러 발생")
            openModal()
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    };
    const SearchBtnClick = async () => {
        try {
            console.log(accessToken)
            setModalText("로딩중")
            setIsLoading(true)
            const response = await axios.get<PointExchangeRequest>(
                BaseUrl + '/admin/point',
                {
                    params: {
                        intRwdAmt: filterMoney,
                    },
                    headers : {
                        authorization : accessToken
                    }
                }
            );
            // 받아온 데이터 사용 예시
            //localStorage.setItem("accessToken", response.headers["authorization"])
            setPointExchangeData(response.data);
            setBtnClickState(true);
            console.log(pointExchangeData)
        } catch (e) {
            setModalText("에러 발생")
            openModal()
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    };
    const [filterMoney, setFilterMoney] = useState(0);
    return (
        <s.WrapperLayout>
            <s.TitleLayout>
                <s.TitleTextStyle>포인트 교환 요청 확인</s.TitleTextStyle>
                <s.IoMdArrowRoundBackIcon onClick={backBtnClick}/>
            </s.TitleLayout>
            <s.ContentLayout>
                <s.FilterLayout>
                    <s.FilterInnerLayout>
                        <s.FilterContainer onClick={() => setFilterMoney(2000)}>2,000</s.FilterContainer>
                        <s.FilterContainer onClick={() => setFilterMoney(3000)}>3,000</s.FilterContainer>
                        <s.FilterContainer onClick={() => setFilterMoney(5000)}>5,000</s.FilterContainer>
                        <s.FilterContainer onClick={() => setFilterMoney(10000)}>10,000</s.FilterContainer>
                    </s.FilterInnerLayout>
                    <s.IconLayout>
                        <MdCancel onClick={() => setFilterMoney(0)} size={32} color={PrimaryGreenColor}/>
                        <s.CurrentFilterText>₩ {filterMoney}</s.CurrentFilterText>
                        <FaSearch onClick={SearchBtnClick} size={28} color={PrimaryGreenColor}/>
                    </s.IconLayout>
                </s.FilterLayout>
                {btnClickState && pointExchangeData && (
                    <>
                        {pointExchangeData.data.map((item: PointExchangeData) => (
                            <s.ContentContainer key={item.id}>
                                <s.ContainerTitleWrapper>
                                    <s.FaCheckCircleIcon color={PurpleBtnColor}/>
                                    <s.ContainerTitleColorText>교환 요청</s.ContainerTitleColorText>
                                    <s.ContainerTitleText>이 확인되었습니다.</s.ContainerTitleText>
                                </s.ContainerTitleWrapper>
                                <s.ContainerDetailText>{`${item.user_name} / ${item.user_account_number} / ${item.point_change_balence}원`}</s.ContainerDetailText>
                                <s.CheckBtnLayout>
                                    <s.CheckBtn onClick={() => SendMoneyBtnClick(item.id)}>송금완료</s.CheckBtn>
                                </s.CheckBtnLayout>
                            </s.ContentContainer>
                        ))}
                        {pointExchangeData.data.length === 0 && (
                            <s.ContentContainer>
                                <s.ContainerTitleWrapper>
                                    <s.FaCheckCircleIcon color={PurpleBtnColor}/>
                                    <s.ContainerTitleColorText>교환 요청이 없습니다.</s.ContainerTitleColorText>
                                </s.ContainerTitleWrapper>
                                <div style={{marginBottom: "12px"}}></div>
                            </s.ContentContainer>
                        )}
                    </>
                )}
            </s.ContentLayout>
            {isLoading && <LoadingComponent isOpen={isLoading} message={modalText}/>} {/* 로딩 상태일 때 모달 표시 */}
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText}/>
        </s.WrapperLayout>
    );
}

export default PointChangeScreen;
