import React, {useEffect, useState} from 'react'
import {s} from './styled'
import {useNavigate} from "react-router-dom";
import {FaCheckCircle} from "react-icons/fa";
import {PrimaryGreenColor, PurpleBtnColor} from "../../components/Data/color";
import {IoMdArrowRoundBack} from "react-icons/io";
import {MdCancel} from "react-icons/md";
import {FaSearch} from "react-icons/fa";
import axios from "axios";
import {BaseUrl} from "../../components/Data/baseUrl";
import {FaHeadphones} from "react-icons/fa6";
import {HelpAskMailData, HelpAskMailDataContent} from "../../components/types/DtoTypes/HelpAskDataBase";
import SuccessModal from "../../components/Modal/SmallModal/SuccessModal";
import LoadingComponent from "../../components/Modal/LoadingModal/LoadingModal";

function HelpAskScreen() {
    const accessToken = localStorage.getItem("accessToken")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리
    const [modalText, setModalText] = useState("로딩중");
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const navigate = useNavigate();
    const backBtnClick = () => {
        navigate(-1)
    }
    const [dataContent, setDataContent]
        = useState<HelpAskMailDataContent[]>([]);
    const mailCheckBtnClick = async (id: number) => {
        try {
            setIsLoading(true)
            setModalText("처리 중...")
            // 서버에서 해당 메일 삭제 API 호출
            const response = await axios.patch(
                BaseUrl + `/admin/ask/changed?id=${id}`,
                {}, {
                    headers: {"authorization": accessToken}
                }
            );
            //localStorage.setItem("accessToken", response.headers["authorization"])
            setIsLoading(false)
            setModalText("처리완료")
            openModal()
            // 클라이언트에서 삭제된 메일을 화면에서 제거
            setDataContent((prevDataContent) =>
                prevDataContent.filter((item) => item.id !== id));

        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)

                setModalText("처리 중...")
                const response = await axios.get<HelpAskMailData>(
                    BaseUrl + '/admin/ask',
                    {
                        headers: {
                            authorization: accessToken
                        }
                    }
                );
                //localStorage.setItem("accessToken", response.headers["authorization"])
                const responseData: HelpAskMailData = response.data;
                const dataContent: HelpAskMailDataContent[] = responseData.data;
                setDataContent(dataContent);
                setIsLoading(false)
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, []); // 빈 배열을 두어 컴포넌트가 마운트될 때 한 번만 실행
    return (
        <s.WrapperLayout>
            <s.TitleLayout>
                <s.TitleTextStyle>문의 내역</s.TitleTextStyle>
                <s.IoMdArrowRoundBackIcon onClick={backBtnClick}/>
            </s.TitleLayout>
            <s.ContentLayout>
                {dataContent.map((item) => (
                    <s.ContentContainer key={item.id}>
                        <s.ContainerTitleWrapper>
                            <s.FaHeadphonesIcon color={PurpleBtnColor}/>
                            <s.HelpUserNameText>{item.name}</s.HelpUserNameText>
                        </s.ContainerTitleWrapper>
                        <s.ContainerEmailText>이메일 : {item.email}</s.ContainerEmailText>
                        <s.ContainerDetailText>{item.content}</s.ContainerDetailText>
                        <s.CheckBtnLayout>
                            <s.CheckMailBtn onClick={() => {
                                mailCheckBtnClick(item.id)
                            }}>답변 완료
                            </s.CheckMailBtn>
                            <s.SendMailBtn href={`mailto:${item.email}`}>답변 작성하기</s.SendMailBtn>
                        </s.CheckBtnLayout>
                    </s.ContentContainer>
                ))}
            </s.ContentLayout>
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText}/>
            {isLoading && <LoadingComponent isOpen={isLoading} message={modalText}/>} {/* 로딩 상태일 때 모달 표시 */}
        </s.WrapperLayout>
    );
}

export default HelpAskScreen;
