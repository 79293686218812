import React, {useState} from 'react'
import {s} from './styled'
import {useNavigate} from "react-router-dom";
import {PrimaryGreenColor} from "../../components/Data/color";
import {IoMdArrowRoundBack} from "react-icons/io";
import axios  from "axios";
import { BaseUrl} from "../../components/Data/baseUrl";
import SuccessModal from "../../components/Modal/SmallModal/SuccessModal";
import LoadingComponent from "../../components/Modal/LoadingModal/LoadingModal";
import {ResponseData} from "../../components/types/DtoTypes/AddNoticeDto";

function AddNoticeScreen() { //공지사항 등록 화면
    const accessToken = localStorage.getItem("accessToken")
    const [noticeTitle, setNoticeTitle] = useState(""); //용우가 입력할 공지사항의 제목.
    const [noticeContent, setNoticeContent] = useState(""); //용우가 입력할 공지사항의 내용.
    const navigate = useNavigate(); // 그냥 화면이동할때 스는 변수임.
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달을 열지 말지 결정하는 변수
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리
    const [modalText, setModalText] = useState(""); // 모달에 띄워줄 텍스트.

    const openModal = () => { setIsModalOpen(true); };
    const closeModal = () => { setIsModalOpen(false); };
    const backBtnClick = () => { navigate(-1) } // -1 로 설정하면 이전 화면으로 이동.
    const noticeRegistBtn = async () => { // "등록"버튼을 클릭하면 실행 할 함수.
        try {
            setModalText("등록 중...") // 모달에 띄워줄 텍스트를 "등록중"으로 설정.
            setIsLoading(true) // 로딩 모달 상태 true. 이러면 모달 띄워짐.
            const response = await axios.post<ResponseData>(// post 요청
                BaseUrl + '/admin/notify/register',
                {
                    mainTitle : noticeTitle,
                    detail : noticeContent,
                },
                {
                    headers:{
                        authorization : accessToken
                    }
                }
                /**
                 * ResponseData
                 * 응답 데이터 Dtos
                 * 응답을 response에 저장함.
                 */
            );
            //localStorage.setItem("accessToken", response.headers["authorization"])
            setModalText(response.data.message); //응답받은 json의 message 값을 모달에 띄워줄 텍스트로 저장. ex)"등록 완료"
            handleButtonClick() // 등록 성공시 띄워줄 모달.
        }catch (e) {
            setModalText("에러 발생")
            openModal()
        }finally {
            setIsLoading(false)
        }
    }
    const handleButtonClick = () => {
        openModal();
    };
    return (
        <s.WrapperLayout>
            <s.TitleLayout>
                <s.TitleWrapper>
                    <s.TitleTestStyle>공지사항 등록</s.TitleTestStyle>
                    <s.IoMdArrowRoundBackIcon color={PrimaryGreenColor} onClick={backBtnClick}/>
                </s.TitleWrapper>
                <s.SubTitleText>공지사항에 등록할 내용을 입력해 주세요.</s.SubTitleText>
            </s.TitleLayout>
            <s.InputFormLayout>
                <s.InputFormTitleText>제목</s.InputFormTitleText>
                <s.InputTitleFormStyle
                    type="text"
                    placeholder="제목을 입력해주세요."
                    value={noticeTitle}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setNoticeTitle(e.target.value)}
                />
                <s.InputFormTitleText>내용</s.InputFormTitleText>
                <s.InputContentFormStyle
                    //type="text"
                    placeholder="내용을 입력해주세요."
                    value={noticeContent}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setNoticeContent(e.target.value)}
                />
                <s.RegisterButton type={"button"} onClick={noticeRegistBtn}>등록</s.RegisterButton>
            </s.InputFormLayout>
            <s.TextButton onClick={backBtnClick}>이전 화면으로 이동</s.TextButton>
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText} />
            {isLoading && <LoadingComponent isOpen={isLoading} message={modalText}/>} {/* 로딩 상태일 때 모달 표시 */}
        </s.WrapperLayout>
    );
}
export default AddNoticeScreen;
