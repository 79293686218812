import styled from "styled-components";
import MainBannerSrc from '../../assets/Img/MainBanner.jpg';
import {PrimaryGreenColor, TextBlack} from "../../components/Data/color";

const WrapperLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  // 모바일 화면에서 적용될 스타일
  @media (max-width: 768px) {
    margin-top: 32px;
  }
  @media (max-width: 1024px) {
    margin-top: 32px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 640px;
  height: 750px;
  margin-right: 40px;
  margin-left: 40px;
  justify-content: center;
  border-radius: 24px;
  border-color: ${TextBlack};
  border-width : 1px;
  background-color: white;
  flex-direction: column;
  margin-bottom: 100px;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.4); /* 그림자 설정 */
  
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 12px;
    margin-right: 12px;
    height: 600px;
    margin-bottom: 40px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%;
    margin-left: 12px;
    margin-right: 12px;
    height: 1000px;
    margin-bottom: 40px;
  }

`;

const MainBannerImg = styled.img.attrs({
    src: MainBannerSrc
})`
  margin-bottom: 100px;
  width: 336px;
  border : 3px solid white;
  border-radius: 24px;
  display: block;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  
  // 모바일 화면에서 적용될 스타일
  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 50px;
    //margin: 0;
    //box-shadow: none;
  }
`;

const InputFormLayout = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 640px; 
  // 모바일 화면에서 적용될 스타일
  @media (max-width: 768px) {
    width: 100%;
    gap : 16px;
    //margin: 0;
    //box-shadow: none;
  }
`;
const InputFormStyle = styled.input`
  padding: 10px;
  font-size: 20px;
  display: flex;
  border-radius: 12px;
  height: 40px;
  width: 75%;
  border : 2px solid grey;
  // 모바일 화면에서 적용될 스타일
  @media (max-width: 768px) {
    font-size: 16px;
    width: 85%;
    //margin: 0;
    //box-shadow: none;
  }
`;

const LoginButton  = styled.button`
  display: flex;
  width : 30%;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 80px;
  border-radius: 12px;
  border : none;
  background-color : ${PrimaryGreenColor};
  color: white;
  &:active {
    background-color: #8ebf8c;
    box-shadow: inset -.3rem -.1rem 1.4rem  #5d965a, inset .3rem .4rem .8rem #448241;
    cursor: pointer;
  }
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  // 모바일 화면에서 적용될 스타일
  @media (max-width: 768px) {
    font-size: 18px;
    margin-top: 24px;
    
  }
`;

export const s = {
    WrapperLayout,
    InnerContainer,
    MainBannerImg,
    InputFormStyle,
    InputFormLayout,
    LoginButton,

}
