// ModalComponent.tsx
import React from 'react';
import Modal from 'react-modal';
import {s} from './styled'
import {PrimaryGreenColor} from "../../Data/color";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius : "24px",
        backgroundColor: "white",
        border: `1px solid ${PrimaryGreenColor}`, // 다른 스타일 속성도 필요에 따라 추가할 수 있습니다.
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 모달 뒷 배경의 투명도 조절
    },
};
interface ModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    message : string;
}

const SuccessModal: React.FC<ModalProps> = ({ isOpen, onRequestClose, message }) => {
    return (
        <Modal isOpen={isOpen}
               onRequestClose={onRequestClose}
               style={customStyles} contentLabel="Example Modal">
            <s.ModalInnerContainer>
                <s.TitleContainer>알림</s.TitleContainer>
                <s.ContentContainer>{message}</s.ContentContainer>
                <s.BtnContainer>
                    <s.BtnStyle type={"button"} onClick={onRequestClose}>확인
                    </s.BtnStyle>
                </s.BtnContainer>
            </s.ModalInnerContainer>
        </Modal>
    );
};

export default SuccessModal;
