// dateValidation.js

function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function isValidDate(dateString : string) {
    const datePattern = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    const matchResult = dateString.match(datePattern);

    if (!matchResult) {
        return false; // 정규식 매칭 실패 시 유효하지 않은 날짜
    }

    const year = parseInt(matchResult[1], 10);
    const month = parseInt(matchResult[2], 10);
    const day = parseInt(matchResult[3], 10);

    const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    return day <= daysInMonth[month - 1];
}
export default isValidDate;
