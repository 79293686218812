import React, {useEffect, useState} from 'react';
import {s} from "./styled";
import {FaCheck} from "react-icons/fa";
import {MdOutlineSaveAlt} from "react-icons/md";
import {LuClipboardSignature} from "react-icons/lu";
import axios from "axios";
import {BaseUrl} from "../../../../components/Data/baseUrl";
import LoadingComponent from "../../../../components/Modal/LoadingModal/LoadingModal";
import {ContentItem, Sort, Pageable, InterviewData} from "../../../../components/types/DtoTypes/InterviewDto"
import copy from 'copy-to-clipboard'
import SuccessModal from "../../../../components/Modal/SmallModal/SuccessModal";
function InterviewRequestTab() {
    const [copyStates, setCopyStates] = useState<boolean[]>([]);
    const [surveyData, setSurveyData] = useState<InterviewData | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [modalText, setModalText] = useState("로딩중");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const accessToken = localStorage.getItem("accessToken")
    const fetchMoreData = async () => {
        if (!hasMore || loading) return; // 추가 데이터가 없거나 이미 로딩 중이면 요청을 하지 않음
        setLoading(true);
        try {
           // setModalText("불러오는 중...")
         //   setIsLoadingModal(true)
            const response = await axios.get<InterviewData>(
                `${BaseUrl}/admin/pre-register/interview?page=${currentPage}&size=10`,
                {
                    headers : {
                        authorization : accessToken
                    }
                },

            );
            setSurveyData(prevData => {
                const newData = response.data.content;
                if (!prevData) return {...response.data, content: newData};

                // 새로운 데이터 중에서 기존 데이터에 없는 항목만 필터링
                const filteredNewData = newData.filter(newItem =>
                    !prevData.content.some(existingItem => existingItem.createdTimestamp === newItem.createdTimestamp));
                return {
                    ...prevData,
                    content: [...prevData.content, ...filteredNewData],
                    pageable: response.data.pageable
                };
            });
            //localStorage.setItem("accessToken", response.headers["authorization"])
            setCurrentPage(prevPage => prevPage + 1);
            setHasMore(!response.data.last);
        } catch (error) {
            setModalText("에러가 발생했습니다.")
            openModal()
            console.error("Error fetching data: ", error);
        }/*finally {
            setIsLoadingModal(false)
        }*/
        setLoading(false);
    };
    // 스크롤 핸들러
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100) return;
            fetchMoreData();
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [surveyData, loading]); // loading 상태 추가
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
// 초기 데이터 로딩 useEffect
    useEffect(() => {
        fetchMoreData(); // 초기 데이터 로딩 시 currentPage를 증가시키지 않음
    }, []);
    const copyBtnClick = (index: number, item : ContentItem) => {
        const copiedText =
            '의뢰자 직무 : '  + item.taskTpCd + '\n' +
            '회사명 : '  + item.compNm + '\n' +
            '아이템 한 줄 소개 : '  + item.itemOneLine + '\n' +
            '인터뷰 매칭 방법 : '  + item.interviewMetchTpCd + '\n' +
            '예상 소요 시간(분) : '  + item.exceptTime + '\n' +
            '인터뷰 보상 : '  + item.interviewRewdAmt + '\n' +
            '원하는 인터뷰이 특성 : '  + item.whatYouWant + '\n' +
            '원하는 인원 : '  + item.interviewEntryCnt + '\n' +
            '기타 요청 사항 : '  + item.etcReqCn + '\n' +
            '인터뷰이 매칭 : '  + item.matching + '\n' +
            '인터뷰 질문 개발 : '  + item.question + '\n' +
            '인터뷰 진행 대행 : '  + item.instead + '\n' +
            '질문 개발, 진행 대행 의뢰시 인터뷰 목적 기재 : '  + item.questionDetail + '\n' +
            '생성 날짜 : '  + item.createdTimestamp + '\n';
        alert(copiedText)
        setCopyStates((prevCopyStates: boolean[]) => { // prevCopyStates의 타입을 명시적으로 boolean[]로 지정
            const newCopyStates = Array(prevCopyStates.length).fill(false);
            newCopyStates[index] = true;
            return newCopyStates;
        });
    };
    return (
        <>
            {surveyData && surveyData.content.map((item, index) => (
                <s.WrapperLayout key={index}>
                    <s.TextLayoutUl>
                        <s.TextLayoutLi content={"의뢰자 직무 : "}>
                            {item.taskTpCd} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"회사명 : "}>
                            {item.compNm} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"아이템 한줄 소개 : "}>
                            {item.itemOneLine} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"인터뷰 매칭 방법 : "}>
                            {item.interviewMetchTpCd} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"예상 소요 시간(분) : "}>
                            {item.exceptTime} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"인터뷰 보상 : "}>
                            {item.interviewRewdAmt} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"원하는 인터뷰이 특성 : "}>
                            {item.whatYouWant} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"원하는 인원 : "}>
                            {item.interviewEntryCnt} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"기타 요청 사항 : "}>
                            {item.etcReqCn} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"인터뷰이 매칭 : "}>
                            {item.matching} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"인터뷰 질문 개발 : "}>
                            {item.question} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"인터뷰 진행 대행 : "}>
                            {item.instead} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"질문 개발, 진행 대행 의뢰시 인터뷰 목적 기재 : "}>
                            {item.questionDetail} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"생성 날짜 : "}>
                            {item.createdTimestamp} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        {/* 나머지 필드에 대한 s.TextLayoutLi 요소들 추가 */}
                    </s.TextLayoutUl>
                    <s.IconButtonLayout>
                        <s.IconButtonContainer onClick={() => copyBtnClick(index, item)} disabled={copyStates[index]}>
                            {copyStates[index] ? (
                                <>
                                    <FaCheck color={"#448241"} size={24}/>
                                    <s.CopyTextStyle>Copied!!</s.CopyTextStyle>
                                </>
                            ) : (
                                <>
                                    <LuClipboardSignature color={"#448241"} size={24}/>
                                    <s.CopyTextStyle>Copy</s.CopyTextStyle>
                                </>
                            )}
                        </s.IconButtonContainer>
                    </s.IconButtonLayout>
                </s.WrapperLayout>
            ))}
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText} />
           {/* {isLoadingModal && <LoadingComponent isOpen={isLoadingModal} message={modalText}/>}  로딩 상태일 때 모달 표시 */}
        </>
    );

}

export default InterviewRequestTab;
