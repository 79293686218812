import styled from "styled-components";
import {PrimaryGreenColor} from "../../Data/color";


const DropDownWrapper = styled.div`
  //background-color: #61dafb;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%; /* 화면 크기에 맞게 조정 */
    //font-size: 1.2rem; /* 글꼴 크기 조정 */
  }
`;
const JustifyBetween = styled.div `
  width: 100%;
  display: flex;
  //background-color: #61dafb;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 12px;
  border : 2px solid grey;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const ArrowStyle = styled.div`
  margin-right: 12px;
  color: grey;
`
const InputFormStyle = styled.div`
  padding: 10px;
  font-size: 20px;
  display: flex;
  color: ${PrimaryGreenColor};
  height: 40px;
  width: 97.5%;
  font-weight: bold;
  //color: grey;
  
  line-height: 40px;
  @media (max-width: 768px) {
    width: 100%;
    height: 20px;
    font-size: 16px;
    align-items: center;
  }
`;

const UlStyle = styled.ul `
  display: flex;
  width: 95.5%;
  flex-direction: column;
  /*padding-left : 32px;
  padding-right: 32px;*/
  border: 1px solid black;
  border-radius: 12px;
  line-height: 40px;
  @media (max-width: 768px) {
    width: 90%;
  }
`
const LIStyle = styled.li `
  display: flex;
  //width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0;
  margin-left: 0;

`

export const s ={
    InputFormStyle,
    DropDownWrapper,
    UlStyle,
    LIStyle,
    JustifyBetween,
    ArrowStyle
}
