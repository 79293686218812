import React, {useEffect, useState} from 'react';
import {s} from './styled';
import {LuClipboardSignature} from "react-icons/lu";
import {FaCheck} from "react-icons/fa";
import axios from "axios";
import {BaseUrl} from "../../../../components/Data/baseUrl";
import LoadingComponent from "../../../../components/Modal/LoadingModal/LoadingModal";
import {ContentItem, Pageable, Sort, SurveyData} from "../../../../components/types/DtoTypes/SurveyDto";
import copy from 'copy-to-clipboard'
import SuccessModal from "../../../../components/Modal/SmallModal/SuccessModal";

function SurveyRequestTab() {
    const [surveyData, setSurveyData] = useState<SurveyData | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [copyStates, setCopyStates] = useState<boolean[]>([]);
    const [modalText, setModalText] = useState("로딩중");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const accessToken = localStorage.getItem("accessToken")
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const fetchMoreData = async () => {
        if (!hasMore || loading) return; // 추가 데이터가 없거나 이미 로딩 중이면 요청을 하지 않음
        setLoading(true);
        try {
            /*     setModalText("불러오는 중...")
                 setIsLoadingModal(true)*/
            const response = await axios.get<SurveyData>(
                `${BaseUrl}/admin/pre-register/survey?page=${currentPage}&size=10`,
                {
                    headers: {
                        authorization : accessToken
                    }
                });
            setSurveyData(prevData => {
                const newData = response.data.content;
                if (!prevData) return {...response.data, content: newData};
                // 새로운 데이터 중에서 기존 데이터에 없는 항목만 필터링
                const filteredNewData = newData.filter(newItem =>
                    !prevData.content.some(existingItem => existingItem.createdTimestamp === newItem.createdTimestamp));
                return {
                    ...prevData,
                    content: [...prevData.content, ...filteredNewData],
                    pageable: response.data.pageable
                };
            });
            //localStorage.setItem("accessToken", response.headers["authorization"])
            setCurrentPage(prevPage => prevPage + 1);
            setHasMore(!response.data.last);
        } catch (error) {
            setModalText("에러가 발생했습니다.")
            openModal()
            console.error("Error fetching data: ", error);
        }
        // finally {
        //     setIsLoadingModal(false)
        // }
        setLoading(false);
    };

// 스크롤 핸들러
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 100) return;
            fetchMoreData();
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [surveyData, loading]); // loading 상태 추가

// 초기 데이터 로딩 useEffect
    useEffect(() => {
        fetchMoreData(); // 초기 데이터 로딩 시 currentPage를 증가시키지 않음
    }, []);

    const copyBtnClick = (index: number, item : ContentItem) => {
        const copiedText =
            '의뢰자 직무 : '  + item.taskTpCd + '\n' +
            '스크리닝 직무 : '  + item.screening + '\n' +
            '구글폼 url : '  + item.completeUrl + '\n' +
            '예상 소요 시간 : '  + item.exceptCountTime + '\n' +
            '원하는 인원 : '  + item.surveyEntryCnt + '\n' +
            '설문 입력 : '  + item.surveyInput + '\n' +
            '설문 응답 : '  + item.surveyRes + '\n' +
            '설문지 개발 : '  + item.surveyDev + '\n' +
            '생성 날짜 : '  + item.createdTimestamp + '\n';
        copy(copiedText); //안드로이드 카카오톡 인앱 브라우저 이슈 대응
        setCopyStates((prevCopyStates) => {
            const newCopyStates = Array(prevCopyStates.length).fill(false);
            newCopyStates[index] = true;
            return newCopyStates;
        });
    };
    return (
        <>
            {surveyData && surveyData.content.map((item, index) => (
                <s.WrapperLayout key={index}>
                    <s.TextLayoutUl>
                        <s.TextLayoutLi content={"의뢰자 직무 : "}>
                            {item.taskTpCd} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"스크리닝 : "}>
                            {item.screening} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"구글폼 url : "}>
                            {item.completeUrl} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"예상 소요 시간 : "}>
                            {item.exceptCountTime} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"원하는 인원 : "}>
                            {item.surveyEntryCnt} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"설문 입력 : "}>
                            {item.surveyInput} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"설문 응답 : "}>
                            {item.surveyRes} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"설문지 개발 : "}>
                            {item.surveyDev} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        <s.TextLayoutLi content={"생성 날짜 : "}>
                            {item.createdTimestamp} {/* 예시로 사용된 필드 이름, 실제 필드 이름에 맞게 수정 필요 */}
                        </s.TextLayoutLi>
                        {/* 나머지 필드에 대한 s.TextLayoutLi 요소들 추가 */}
                    </s.TextLayoutUl>


                    <s.IconButtonLayout>
                        <s.IconButtonContainer onClick={() => copyBtnClick(index,  item)} disabled={copyStates[index]}>
                            {copyStates[index] ? (
                                <>
                                    <FaCheck color={"#448241"} size={24}/>
                                    <s.CopyTextStyle>Copied!!</s.CopyTextStyle>
                                </>
                            ) : (
                                <>
                                    <LuClipboardSignature color={"#448241"} size={24}/>
                                    <s.CopyTextStyle>Copy</s.CopyTextStyle>
                                </>
                            )}
                        </s.IconButtonContainer>
                    </s.IconButtonLayout>

                </s.WrapperLayout>
            ))}
            {/*{isLoadingModal && <LoadingComponent isOpen={isLoadingModal} message={modalText}/>}  로딩 상태일 때 모달 표시 */}
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText} />
        </>
    );
}

export default SurveyRequestTab;
