import styled from "styled-components";
import {TextBlack} from "./Data/color";
interface ImgSrc {
    imgSource: string;
}

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  height: 256px;
  margin-right: 40px;
  margin-left: 40px;
  justify-content: center;
  border-radius: 24px;
  border-color: ${TextBlack};
  border-width: 1px;
  background-color: white;
  flex-direction: column;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.2); /* 그림자 설정 */
  //margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    
  }
`;

const MainBannerImg = styled.img.attrs<ImgSrc>((props) => ({
    src: props.imgSource,
}))`
  width: 100px;
  position: relative; // 이 부분 추가
  border: 2px solid transparent;
  display: block;
  margin-bottom: 12px;
`;
//TEXTBLACK
const menuTitleText = styled.p`
  font-size: 22px;
  color: ${TextBlack};
  font-weight: bolder;
`;

export const s = {
    InnerContainer,
    MainBannerImg,
    menuTitleText

}
