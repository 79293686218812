import styled, {createGlobalStyle} from "styled-components";
import {PrimaryGreenColor, PurpleTextColor} from "../../Data/color";

const GlobalStyle = createGlobalStyle`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  
  //background-color: #61dafb;

`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  align-self: center;
  border: 10px solid #f3f3f3;
  border-top: 10px solid ${PrimaryGreenColor};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  @media (max-width: 768px) {
    font-size: 24px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid ${PrimaryGreenColor};
    width: 50px;
    height: 50px;
  }
`;

const TitleContainer = styled.div`
  font-size : 36px;
  font-weight: bolder;
  align-self: start;
  justify-self: start;
  margin-left: 12px;
  color: ${PrimaryGreenColor};
  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const ContentContainer = styled.div`
  font-size : 24px;
  font-weight: normal;
  margin-top: 60px;
  margin-left: 12px;
  margin-right: 12px ;
  
`;
const ModalInnerContainer = styled.div `
  flex-direction: column;
  width : 400px;
  height: 270px;
  display: flex;
  //background-color: #61dafb;
  @media (max-width: 768px) {
    width: 250px;
    height: 180px;
  }
`

export const s = {
    GlobalStyle,
    LoadingModal,
    LoadingSpinner,
    TitleContainer,
    ContentContainer,
    ModalInnerContainer


}
