import React, {ChangeEvent, useRef, useState} from 'react'
import {s} from './styled'
import DropdownMenu from "../../components/DropDown/DefualtDropDown/DropDown";
import {useNavigate} from "react-router-dom";
import {PrimaryGreenColor} from "../../components/Data/color";
import {IoMdArrowRoundBack} from "react-icons/io";
import SidoDropdownMenu from "../../components/DropDown/SidoDropDown/SidoDropDown";
import SigunguDropdownMenu from "../../components/DropDown/SigunguDropDown/SigunguDropDown";
import {oocpSidoCdDropDatas, oocpSigunguCdDropDatas} from "../../components/Data/addressData";
import isValidDate from '../../components/UtilFunctions/YYYYMMDD'
import axios from "axios";
import {BaseUrl} from "../../components/Data/baseUrl";
import SuccessModal from "../../components/Modal/SmallModal/SuccessModal";
import LoadingComponent from "../../components/Modal/LoadingModal/LoadingModal";
import {ResponseData} from "../../components/types/DtoTypes/AddNoticeDto";

function ResearchRegistPage1() {
    const [researchTitle, setResearchTitle] = useState("");
    const [researchSubTitle, setResearchSubTitle] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [estimateTime, setEstimateTime] = useState("");
    const [detailContent, setDetailContent] = useState("");
    const [reward, setReward] = useState("");
    const [client, setClient] = useState("");
    const [recruitment, setRecruitment] = useState("");
    const [researchTypeDrop, setResearchTypeDrop] = useState("");
    const [targetGenderDrop, setTargetGenderDrop] = useState("");
    const [onOfflineDrop, setOnOfflineDrop] = useState("");
    const [marriedDrop, setMarriedDrop] = useState("");
    const [petCdDrop, setPetCdDrop] = useState("");
    const [jobCdDrop, setJobCdDrop] = useState("");
    const [houseTpCdDrop, setHouseTpCdDrop] = useState("");
    const [sidoDrop, setSidoDrop] = useState("");
    const [sigunguDrop, setSigunguDrop] = useState("");
    const [modalText, setModalText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리
    const accessToken = localStorage.getItem("accessToken")
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleButtonClick = () => {
        // 예시: 특정 조건이 true일 때 모달 열기
        //setSmallModalOpen(true)
        openModal();
    };
    const onClickNextBtn = async () => {
        if (researchTitle != "" && researchSubTitle != "" && dueDate != "" && estimateTime != ""
            && detailContent != "" && researchTypeDrop != "" && reward != "" && client != ""
            && targetGenderDrop != "" && recruitment != ""
            && onOfflineDrop != "" && marriedDrop != "" && jobCdDrop != "" && petCdDrop != ""
            && houseTpCdDrop != "" && sigunguDrop != ""
        ) {
            if (!Number.isFinite(Number(reward))
                || !Number.isFinite(Number(recruitment)
                    || !Number.isFinite(Number(estimateTime)))) {
                return alert("모집일,예상시간, 보상은 숫자로 입력하세요.");

            }
            if (!isValidDate(dueDate)) {
                return alert("마감일 날짜를 형식에 맞게 입력하세요.")
            }
            try {
                setIsLoading(true)
                setModalText("등록 중...")
                const response = await axios.post<ResponseData>(
                    BaseUrl + '/admin/register/research',
                    {
                        mainTitle: researchTitle,
                        subTitle: researchSubTitle,
                        dueDate: dueDate,
                        exceptTime: estimateTime,
                        detail: detailContent,
                        researchType: researchTypeDrop,
                        researchMethTpCd: onOfflineDrop,
                        researchRewdAmt: reward,
                        researchEntryCnt: recruitment,
                        isTeenagers: selectedOptionsString.isTeenagers,
                        isTwenties: selectedOptionsString.isTwenties,
                        isThirties: selectedOptionsString.isThirties,
                        isForties: selectedOptionsString.isForties,
                        isFifties: selectedOptionsString.isFifties,
                        isSixties: selectedOptionsString.isSixties,
                        isSeventies: selectedOptionsString.isSeventies,
                        jobCd: jobCdDrop,
                        wedCd: marriedDrop,
                        genderCd: targetGenderDrop,
                        houseTpCd: houseTpCdDrop,
                        userCd: "기타",
                        occpSidoCd: sidoDrop,
                        occpSigunguCd: sigunguDrop,
                        petCd: petCdDrop
                    },
                    {
                        headers: {authorization: accessToken}

                    }
                )
                handleButtonClick()
                setModalText(response.data.message);
                openModal()
                console.log("성공 ", response)
            } catch (e) {
                setModalText("에러 발생")
                openModal()
                console.log(e)
            } finally {
                setIsLoading(false)
            }
        } else {
            alert("필수 항목을 모두 입력해주세요!")
        }
    }
    const houseTpCdDatas = ["1인 가구", "2인 가구", "3인 가구", "4인 가구", "5인 가구", "6인 이상 가구", "상관없음"]
    const jopCdDropDatas = ["상관없음", "초등 학생", "중학생",
        "고등 학생", "대학(원)생", "유학생",
        "직장인", "공무원", "사업자", "기타"
    ]
    const petCdDropDatas = ['강아지', '고양이', '강아지/고양이', '없음', '기타']
    const researchTypeDropDatas = ['설문조사', '인터뷰', '테스트'];
    const onOffTypeDropDatas = ['온라인', '오프라인', '온라인/오프라인'];
    const targetGenderDropDatas = ['여성', '남성', '남성/여성'];
    const marriedTypeDropDatas = ['재혼', '이혼', '미혼', '기혼', '없음'];
    const sigunguDropDatas = oocpSigunguCdDropDatas[sidoDrop];
    const backBtnClick = () => {
        navigate(-1)
    }
    const [selectedOptionsString, setSelectedOptionsString] = useState({
        isTeenagers: "Y",
        isTwenties: "Y",
        isThirties: "Y",
        isForties: "Y",
        isFifties: "Y",
        isSixties: "Y",
        isSeventies: "Y",
    });
    const [selectedOptions, setSelectedOptions] = useState({
        isTeenagers: true,
        isTwenties: true,
        isThirties: true,
        isForties: true,
        isFifties: true,
        isSixties: true,
        isSeventies: true,
    });
    const navigate = useNavigate();
    const [sidoSelected, setSidoSelected] = useState(true);
    const researchTypeDropSelect = (selectedOption: string) => {
        setResearchTypeDrop(selectedOption);
    };
    const onOffTypeDropSelect = (selectedOption: string) => {
        setOnOfflineDrop(selectedOption);
    };
    const targetGenderDropSelect = (selectedOption: string) => {
        setTargetGenderDrop(selectedOption);
    };
    const marriedDropSelect = (selectedOption: string) => {
        setMarriedDrop(selectedOption);
    };
    const jobCdSelect = (selectedOption: string) => {
        setJobCdDrop(selectedOption);
    };
    const petCdSelect = (selectedOption: string) => {
        setPetCdDrop(selectedOption);
    };
    const houseCpCdSelect = (selectedOption: string) => {
        setHouseTpCdDrop(selectedOption);
    };
    const sidoSelect = (selectedOption: string) => {
        setSidoSelected(false)
        setSidoDrop(selectedOption);
    };
    const sigunguSelect = (selectedOption: string) => {
        setSigunguDrop(selectedOption); // 선택한 시/도 값을 저장

    };
    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [name]: checked,
        }));
        setSelectedOptionsString((prevOptions) => ({
            ...prevOptions,
            [name]: checked ? "Y" : "N", // 체크 여부에 따라 "Y" 또는 "N" 설정
        }));
    };
    return (
        <s.WrapperLayout>
            <s.TitleLayout>
                <s.TitleWrapper>
                    <s.TitleTestStyle>리서치 등록</s.TitleTestStyle>
                    <s.IoMdArrowRoundBackIcon color={PrimaryGreenColor} onClick={backBtnClick}/>
                </s.TitleWrapper>
                <s.SubTitleText>등록할 리서치의 상세 항목을 입력해주세요.</s.SubTitleText>
            </s.TitleLayout>
            <s.InputFormLayout>
                <s.InputFormTitleText>리서치 제목</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={researchTitle}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setResearchTitle(e.target.value)}
                />
                <s.InputFormTitleText>리서치 부제목</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={researchSubTitle}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setResearchSubTitle(e.target.value)}
                />
                <s.InputFormTitleText>마감일</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="yyyy-mm-dd"
                    value={dueDate}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setDueDate(e.target.value)}
                />
                <s.InputFormTitleText>예상 소요 시간(분)</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={estimateTime}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setEstimateTime(e.target.value)}
                />
                <s.InputFormTitleText>참여가능 연령대</s.InputFormTitleText>
                <s.CheckBoxLayout>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isTeenagers"
                            checked={selectedOptions.isTeenagers}
                            onChange={handleOptionChange}
                        />
                        10대 ~ 20대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isTwenties"
                            checked={selectedOptions.isTwenties}
                            onChange={handleOptionChange}
                        />
                        20대 ~ 30대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isThirties"
                            checked={selectedOptions.isThirties}
                            onChange={handleOptionChange}
                        />
                        30대 ~ 40대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isForties"
                            checked={selectedOptions.isForties}
                            onChange={handleOptionChange}
                        />
                        40대 ~ 50대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isFifties"
                            checked={selectedOptions.isFifties}
                            onChange={handleOptionChange}
                        />
                        50대 ~ 60대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isSixties"
                            checked={selectedOptions.isSixties}
                            onChange={handleOptionChange}
                        />
                        60대 ~ 70대
                    </s.LabelTextStyle>
                    <s.LabelTextStyle>
                        <s.InputTextStyle
                            type="checkbox"
                            name="isSeventies"
                            checked={selectedOptions.isSeventies}
                            onChange={handleOptionChange}
                        />
                        70대 이상
                    </s.LabelTextStyle>
                </s.CheckBoxLayout>
                <s.InputFormTitleText>리서치 상세 내용</s.InputFormTitleText>
                <s.InputContentFormStyle
                    placeholder="입력해주세요"
                    value={detailContent}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setDetailContent(e.target.value)}
                />
                <s.InputFormTitleText>리서치 종류</s.InputFormTitleText>
                <DropdownMenu options={researchTypeDropDatas} onSelect={researchTypeDropSelect}/>
                <s.InputFormTitleText>온오프라인 여부</s.InputFormTitleText>
                <DropdownMenu options={onOffTypeDropDatas} onSelect={onOffTypeDropSelect}/>
                <s.InputFormTitleText>리서치 보상</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={reward}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setReward(e.target.value)}
                />
                <s.InputFormTitleText>의뢰자명 or 회사명</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={client}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setClient(e.target.value)}
                />
                <s.InputFormTitleText>타겟 성별</s.InputFormTitleText>
                <DropdownMenu options={targetGenderDropDatas} onSelect={targetGenderDropSelect}/>
                <s.InputFormTitleText>모집 인원</s.InputFormTitleText>
                <s.InputFormStyle
                    type="text"
                    placeholder="입력해주세요"
                    value={recruitment}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setRecruitment(e.target.value)}
                />
                <s.InputFormTitleText>결혼 여부</s.InputFormTitleText>
                <DropdownMenu options={marriedTypeDropDatas} onSelect={marriedDropSelect}/>
                <s.InputFormTitleText>직업</s.InputFormTitleText>
                <DropdownMenu options={jopCdDropDatas} onSelect={jobCdSelect}/>
                <s.InputFormTitleText>반려동물</s.InputFormTitleText>
                <DropdownMenu options={petCdDropDatas} onSelect={petCdSelect}/>
                <s.InputFormTitleText>거주형태</s.InputFormTitleText>
                <DropdownMenu options={houseTpCdDatas} onSelect={houseCpCdSelect}/>
                <s.InputFormTitleText>(시,도) // (시군구)</s.InputFormTitleText>
                <s.SidogunguLayout>
                    <SidoDropdownMenu options={oocpSidoCdDropDatas} onSelect={sidoSelect}/>
                    <SigunguDropdownMenu options={sigunguDropDatas} onSelect={sigunguSelect} sidoSelect={sidoSelected}/>
                </s.SidogunguLayout>
                <s.RegisterButton type={"button"} onClick={onClickNextBtn}>등록</s.RegisterButton>
            </s.InputFormLayout>
            <s.TextButton onClick={backBtnClick}>이전 화면으로 이동</s.TextButton>
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText}/>
            {isLoading && <LoadingComponent isOpen={isLoading} message={modalText}/>} {/* 로딩 상태일 때 모달 표시 */}
        </s.WrapperLayout>
    )
}

export default ResearchRegistPage1
