import styled from "styled-components";
import {PrimaryGreenColor, TextBlack} from "../../Data/color";

interface DropDownWrapperProps {
    hidden: boolean;
}
const DropDownWrapper = styled.div<DropDownWrapperProps>`
  //background-color: #61dafb;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => 
        (props.hidden ? 'hidden' : 'visible')};

  @media (max-width: 768px) {
    width: 70%;

  }
`;
const JustifyBetween = styled.div `
  width: 100%;
  display: flex;
  //background-color: #61dafb;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 12px;
  border : 2px solid grey;
  align-items: center;
  @media (max-width: 768px) {
    width: 80%;

  }
`
const ArrowStyle = styled.div`
  margin-right: 12px;
  color: grey;
`
const InputFormStyle = styled.div`
  padding: 10px;
  font-size: 20px;
  display: flex;
  color: ${PrimaryGreenColor};
  height: 40px;
  width: 97.5%;
  font-weight: bold;
  //color: grey;
  line-height: 40px;
  @media (max-width: 768px) {
    width: 80%;
    height: 20px;
    align-items: center;
    font-size: 12px;
    //background-color: #61dafb;
  }
`;

const UlStyle = styled.ul `
  display: flex;
  width: 95.5%;
  flex-direction: column;
  /*padding-left : 32px;
  padding-right: 32px;*/
  border: 1px solid ${TextBlack};
  border-radius: 12px;
  line-height: 40px;
  @media (max-width: 768px) {
    width: 80%;
  }
`
const LIStyle = styled.li `
  display: flex;
  //width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0;
  margin-left: 0;
  

`

export const s ={
    InputFormStyle,
    DropDownWrapper,
    UlStyle,
    LIStyle,
    JustifyBetween,
    ArrowStyle
}
