import styled from "styled-components";
import {
    PurpleBtnColor,
    TextBlack
} from "../../components/Data/color";
import {IoMdArrowRoundBackIcon} from "../../components/IconCss/styled";

const WrapperLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  flex-direction: column;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;
const TitleLayout = styled.div`
  display: flex;
  width: 900px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 90%; /* 화면 크기에 맞게 조정 */
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 100%; /* 화면 크기에 맞게 조정 */
  }
`

const TitleTestStyle = styled.div `
  display: flex;
  width : 900px;
  justify-content: flex-start;
  font-size: 36px;
  font-weight: bolder;
  color: ${TextBlack};
  
  @media (max-width: 768px) {
    font-size: 24px;
    width: 80%;
    justify-content: space-between;
  }
`

const SubTitleText = styled.div`
  display: flex;
  color : grey;
  margin-top: 24px;
  font-weight: bold;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    width:100%;
  }
`

const InputFormTitleText = styled.div`
  display: flex;
  width: 900px;
  color : ${TextBlack};
  margin-top: 24px;
  font-weight: bold;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0;
    width: 100%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 96%; /* 화면 크기에 맞게 조정 */
  }
`
const InputFormLayout = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 64px;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 90%; /* 화면 크기에 맞게 조정 */
  }
`;
const InputTitleFormStyle = styled.input`
  padding: 10px;
  font-size: 20px;
  display: flex;
  border-radius: 12px;
  height: 40px;
  width: 98%;
  border : 2px solid grey;
  
  @media (max-width: 768px) {
    width: 92%;
    height: 20px;
    font-size: 16px;
  }
`;
const InputContentFormStyle = styled.textarea`
  padding: 10px;
  font-size: 20px;
  display: flex;
  border-radius: 12px;
  width: 98%;
  border : 2px solid grey;
  height: 400px;
  @media (max-width: 768px) {
    width: 92%;
    font-size: 16px;
  }
`;
const RegisterButton  = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 12px;
  border: none;
  background-color: ${PurpleBtnColor};
  color: white;
  margin-top: 48px;

  &:active {
    background-color: ${PurpleBtnColor};
    box-shadow: inset -.3rem -.1rem 1.4rem #ae75e0, inset .3rem .4rem .8rem #ba8ed7;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 96%;
    font-size : 20px;
    height: 40px;
    margin-top: 24px;
  }
`;

const TextButton = styled.div`
  display: flex;
  color : grey;
  margin-top: 32px;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
export const s = {
    WrapperLayout,
    TitleLayout,
    TitleTestStyle,
    SubTitleText,
    InputFormTitleText,
    InputFormLayout,
    InputTitleFormStyle,
    InputContentFormStyle,
    RegisterButton,
    TextButton,
    TitleWrapper,
    IoMdArrowRoundBackIcon,
};
