import styled from "styled-components";
import {
    PrimaryGreenColor,
    PurpleSoftTextColor,
    TextBlack
} from "../../components/Data/color";
import {FaCheckCircleIcon, FaHeadphonesIcon, IoMdArrowRoundBackIcon} from "../../components/IconCss/styled";

const WrapperLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  flex-direction: column;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;
const TitleLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 96%; /* 화면 크기에 맞게 조정 */  
  } 
`;

const TitleTextStyle = styled.div `
  display: flex;
  width : 900px;
  background-color: #FCFCFC;
  justify-content: flex-start;
  font-size: 36px;
  font-weight: bolder;
  color: ${TextBlack};
  @media (max-width: 768px) {
    font-size: 24px;
    width: 90%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
  width: 96%; /* 화면 크기에 맞게 조정 */
} `

const ContentLayout = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 900px;
  margin-top: 64px;
  @media (max-width: 768px) {
    width: 96%;
    margin-top: 32px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 96%; /* 화면 크기에 맞게 조정 */
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`
const ContainerTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`
const ContainerTitleText = styled.div`
  margin-left: 8px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`
const HelpUserNameText = styled.div`
  margin-left: 8px;
  color: ${PurpleSoftTextColor};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const ContainerEmailText = styled.div`
  color: ${PrimaryGreenColor};
  font-size: 22px;
  font-weight: bold;
  margin: 12px 72px 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 56px;
    
  }
`;

const ContainerDetailText = styled.div`
  font-size: 20px;
  color: ${TextBlack};
  font-weight: bold;
  margin: 12px 72px 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-left: 56px;
  }
`;


const FilterLayout = styled.div`
  display: flex;
  width: 900px;
  flex-direction: row;
  justify-content: space-between;
`
const FilterInnerLayout = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: center;
`
const FilterContainer = styled.div `
  border: 2px solid ${PrimaryGreenColor};
  padding :12px;
  
  border-radius: 12px;
  margin-right: 24px;
  color: ${PrimaryGreenColor};
  font-weight: bold;
`
const CurrentFilterText  = styled.div`
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-bottom: 2px solid grey;
`
const SendMailBtn = styled.a`
  border: 2px solid ${PrimaryGreenColor};
  border-radius: 8px;
  width: 120px;
  text-align: center;
  padding:  8px;
  margin-bottom: 12px;
  margin-right: 12px;
  font-weight: bold;
  color : ${PrimaryGreenColor};
  
  @media (max-width: 768px) {
    font-size: 12px;
    width: 80px;
    padding: 4px;
    align-self: center;
  }
`
const CheckMailBtn = styled.div`
  border: 2px solid ${PrimaryGreenColor};
  border-radius: 8px;
  width: 100px;
  text-align: center;
  padding:  8px;
  margin-bottom: 12px;
  margin-right: 12px;
  font-weight: bold;
  color : ${PrimaryGreenColor};
  @media (max-width: 768px) {
    font-size: 12px;
    width: 80px;
    padding: 4px;
    align-self: center;
  }
`
const CheckBtnLayout= styled.div`
  display: flex;
  justify-content: end;
  
`
export const s = {
    WrapperLayout,
    TitleLayout,
    TitleTextStyle,
    ContentLayout,
    ContentContainer,
    ContainerTitleText,
    ContainerDetailText,
    ContainerTitleWrapper,
    HelpUserNameText,
    FilterLayout,
    FilterContainer,
    CurrentFilterText,
    FilterInnerLayout,
    SendMailBtn,
    CheckBtnLayout,
    CheckMailBtn,
    ContainerEmailText,
    IoMdArrowRoundBackIcon,
    FaCheckCircleIcon,
    FaHeadphonesIcon,

};
