import styled from "styled-components";
import MainBannerSrc from '../../assets/Img/MainBanner.jpg';
import {PrimaryGreenColor, TextBlack} from "../../components/Data/color";


const WrapperLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  flex-direction: column;
  ;
  /*@media (min-width: 600px) {
    max-width: 600px; !* 원하는 최대 너비 값으로 설정 *!
    margin: 0 auto; !* 좌우 중앙 정렬 *!
  }*/
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center; 
    margin-top: 80px;
  }
`;

const RowLayout = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  //background-color: aqua;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    //box-shadow: none;
  }
`;
const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 120px;
  justify-content: center;
  border-radius: 24px;
  border: 2px solid ${PrimaryGreenColor};
  //background-color: white;
  margin: 64px 40px 200px;
  flex-direction: column;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.23); /* 그림자 설정 */
  //margin-bottom: 12px;
`;

const LogoutText = styled.div`
  margin-top: 12px;
  font-size: 16px;
  font-weight: bolder;
  color: ${PrimaryGreenColor};
`
export const s = {
    WrapperLayout,
    RowLayout,
    LogoutContainer,
    LogoutText,

}
