import React, {useRef, useState} from 'react';
import {s} from './styled';
import exp from "constants";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {BaseUrl} from "../../components/Data/baseUrl";
import {Simulate} from "react-dom/test-utils";
import paste = Simulate.paste;
import {LoginResponseBody} from "../../components/types/DtoTypes/loginDto";
import {observe} from "web-vitals/dist/modules/lib/observe";
import SuccessModal from "../../components/Modal/SmallModal/SuccessModal";
import LoadingComponent from "../../components/Modal/LoadingModal/LoadingModal";

function LoginScreen() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리
    const [modalText, setModalText] = useState("로딩중");
    const [btnClickState, setBtnClickState] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const onLoginBtnClick = async () => {
        try {
            setIsLoading(true)
            setModalText("로그인 중...")

            openModal()
            const encodedEmail = btoa(email.trim())
            const encodedPassword = btoa(password.trim())
            const response = await axios.post<LoginResponseBody> (
                BaseUrl + `/admin/login`,
                {
                    id : encodedEmail,
                    pw : encodedPassword

                }
            )
            const accessToken = response.headers["authorization"]
            //const refreshToken = response.headers["reauthorization"]
            localStorage.setItem("accessToken", accessToken);
            //localStorage.setItem("refreshToken", refreshToken);

            closeModal()
            navigate("/Home");

        }
        catch (e) {
            //alert("실패")
            console.log(e)
        }
        finally {
            setIsLoading(false)
        }

    }
    return (
        <s.WrapperLayout>
            <s.InnerContainer>
                <s.MainBannerImg></s.MainBannerImg>
                <s.InputFormLayout>
                    <s.InputFormStyle
                        type="text"
                        placeholder="ID"
                        value={email}
                        onChange={(e: {
                            target: { value: React.SetStateAction<string> };
                        }) => setEmail(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // email 입력 후 엔터를 누르면 비밀번호 입력란으로 포커스 이동
                                passwordInputRef.current && passwordInputRef.current.focus();
                            }
                        }}
                    />
                    <s.InputFormStyle
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e: {
                            target: { value: React.SetStateAction<string> };
                        }) => setPassword(e.target.value)}
                        ref={passwordInputRef} // 비밀번호 input에 ref 추가
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                // 폼 제출 (여기서는 로그인 버튼 클릭 동작을 대신함)
                                onLoginBtnClick();
                            }
                        }}
                    />
                </s.InputFormLayout>

                <s.LoginButton onClick={onLoginBtnClick}>로그인
                </s.LoginButton>
            </s.InnerContainer>
            <SuccessModal isOpen={isModalOpen} onRequestClose={closeModal} message={modalText}/>
            {isLoading && <LoadingComponent isOpen={isLoading} message={modalText}/>} {/* 로딩 상태일 때 모달 표시 */}
        </s.WrapperLayout>
    )
}


export default LoginScreen;
