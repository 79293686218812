import styled from "styled-components";
import {TextBlack} from "../../components/Data/color";
import {IoMdArrowRoundBackIcon} from "../../components/IconCss/styled";

const WrapperLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 120px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    margin-top: 40px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 900px;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    /* 여기에 iPad에 대한 스타일을 작성하세요 */
    width: 700px;
  }
`

const TitleText = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 36px;
  font-weight: bolder;
  color: ${TextBlack};
  @media (max-width: 768px) {
    font-size: 24px;
  }
  
`

const TabContainer = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 900px;
  border : none;
  margin-top: 32px;
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    /* 여기에 iPad에 대한 스타일을 작성하세요 */
    width: 700px;
  
  }
`;
const TabButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 300px;
  height: 72px;
  font-size: 20px;
  font-weight: normal;
  color: '${TextBlack}';
  border-bottom: 3px solid ${props => (props.active ? "#C9A7EB" : "#757575")};
  color: ${props => (props.active ? TextBlack : "#757575")};
  @media (max-width: 768px) {
    font-size: 16px;
  }`
;

export const s = {
    WrapperLayout,
    TitleText,
    TabContainer,
    TabButton,
    TitleWrapper,
    IoMdArrowRoundBackIcon,
}
