import React, {useState} from 'react';
import {s} from "./styled";
import InterviewRequestTab from "./HomeMenuContainer/InterviewRequestTab/InterviewRequestTab";
import SurveyRequestTab from "./HomeMenuContainer/SurveyRequestTab/SurveyRequestTab";
import TesterRequestTab from "./HomeMenuContainer/TesterRequestTab/TesterRequestTab";
import {IoMdArrowRoundBack} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {PrimaryGreenColor} from "../../components/Data/color";

interface TabViewProps {
    index: number;
    name: string;
    content: JSX.Element;
}

function SurveyRequestScreen() {
    const tabs: TabViewProps[] = [
        {index: 1, name: '설문 요청', content: <SurveyRequestTab/>},
        {index: 2, name: '인터뷰 요청', content: <InterviewRequestTab/>},
        {index: 3, name: 'FGI 요청', content: <TesterRequestTab/>},
    ];
    const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    const backBtnClick = () => {
        navigate(-1)
    }

    return (
        <s.WrapperLayout>
            <s.TitleWrapper>
                <s.TitleText>리서치 요청 확인</s.TitleText>
                <s.IoMdArrowRoundBackIcon color={PrimaryGreenColor} onClick={backBtnClick} />
            </s.TitleWrapper>
            <s.TabContainer>
                {tabs.map((el, idx) => (
                    <s.TabButton
                        key={el.index}
                        onClick={() => {
                            setActiveTab(idx);
                        }}
                        active={activeTab === idx}>
                        {el.name}
                    </s.TabButton>
                ))}
            </s.TabContainer>
            <section>{tabs[activeTab].content}</section>
        </s.WrapperLayout>
    );
}
export default SurveyRequestScreen
