import React from 'react';
import {s} from './styled'
import {PrimaryGreenColor} from "../../Data/color";
import Modal from "react-modal";
import {GrIndicator} from "react-icons/gr";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: "24px",
        backgroundColor: "white",
        border: `1px solid ${PrimaryGreenColor}`, // 다른 스타일 속성도 필요에 따라 추가할 수 있습니다.
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 모달 뒷 배경의 투명도 조절
    },
};

interface ModalProps {
    isOpen: boolean;

    message: string;
}

const LoadingComponent: React.FC<ModalProps> = ({isOpen, message}) => {
    return (
        <Modal isOpen={isOpen}
               style={customStyles} contentLabel="Example Modal">
            <s.GlobalStyle/>
            <s.ModalInnerContainer>
                <s.TitleContainer>{message}</s.TitleContainer>
                    <s.LoadingSpinner/>
            </s.ModalInnerContainer>

        </Modal>
    );
}


export default LoadingComponent;
