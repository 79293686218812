import styled from "styled-components";
import {
    PrimaryGreenColor,
    SkyBlueBackgroundColor,
    SkyBlueTextColor,
} from "../../../../components/Data/color";
interface TextLayoutLiProps {
    content: string;
}
const WrapperLayout = styled.div`
  border: 1px solid ${SkyBlueBackgroundColor};
  margin-top: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  background-color: ${SkyBlueBackgroundColor};
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.3);
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 700px) and (max-width: 1024px) {
    width: 700px;
    text-align: start;
  }
`;
const TextLayoutUl = styled.ul`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 16px;
  margin-bottom: 32px;
`;
const TextLayoutLi = styled.li<TextLayoutLiProps>`
  font-size: 20px;
  font-weight: normal;
  color: ${SkyBlueTextColor};
  margin-top: 20px;
  line-height: 100%;

  &::before {
    content: ${(props) => `"${props.content}"`};
    font-weight: bold;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 300px;
  }
`;
const IconButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  flex-direction: row;
`
const IconButtonContainer = styled.button `
  align-self: flex-end;
  margin-bottom: 16px;
  margin-right: 24px;
  display: flex;
  padding : 8px;
  border-radius: 12px;
  flex-direction: row;
  border: 2px solid ${PrimaryGreenColor};
  background-color: transparent;
`
const CopyTextStyle = styled.div`
  font-size: 16px;
  color : ${PrimaryGreenColor};
  line-height: 24px;
  text-align: center;
  padding:  0;
  margin-left: 8px;
  
;
`
export const s = {
    WrapperLayout,
    TextLayoutUl,
    TextLayoutLi,
    IconButtonLayout,
    CopyTextStyle,
    IconButtonContainer
}

