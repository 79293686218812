import React from "react";
import {s} from "./styled"
import {MenuContanerProps} from "./types/PropsTypes/HomeMenyInterface";
import {useNavigate} from "react-router-dom";

function MenuContainer(props: MenuContanerProps) {
    const {targetRouter, menuTitle, icon} = props;
    const navigate = useNavigate();
    const containerClick = () => {
        navigate(targetRouter); // 클릭하면 해당 URL도 이동.
    }
    return (
        <s.InnerContainer onClick={containerClick}>
            <s.MainBannerImg imgSource={String(icon)}></s.MainBannerImg>
            <s.menuTitleText>{menuTitle}</s.menuTitleText>
        </s.InnerContainer>
    );
}

export default MenuContainer;
